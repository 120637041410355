import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import Slide from '../../components/Slide';
import Owldemo1 from '../../Owldemo1';

export default function Homescreen() {
    return (
        <>

            <Owldemo1 />


            <section className="about-section">
                <div className="auto-container">
                    <div className="row g-3">

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-image">
                                <div className="shape-1" style={{ backgroundImage: "url(assets/images/shape/shape-4.png)" }}></div>
                                <div className="shape-2" style={{ backgroundImage: "url(assets/images/shape/shape-5.png)," }}></div>
                                <figure className="image">
                                    <img src="assets/images/about-us-one.jpg" alt="" />
                                </figure>
                                <div className="video-box">
                                    <div className="image-box">
                                        <img src="assets/images/about-us-biomax.jpg" alt="" />
                                    </div>

                                </div>
                                <div className="shape-3" style={{ backgroundImage: "url(assets/images/shape/shape-6.png)" }}></div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content">
                                <div className="title-box">
                                    <h5 className="sec-sub-title">About Us</h5>
                                    <div className="line-box">
                                        <span className="line-1"></span>
                                        <span className="line-2"></span>
                                        <span className="line-3"></span>
                                    </div>
                                    <h2 className="sec-title">Biomax Biotechnics Private Limited</h2>
                                    <p>A leading manufacturer and supplier of DCGI Approved formulations such as tablets,
                                        injections and new drugs, Biomax Biotechnics Pvt. Ltd., has etched a position for
                                        itself across the Indian pharmaceutical market. The product portfolio includes
                                        formulations for Analgesics, Antibiotic & Anti-Infective, Anti-viral, & Anti
                                        Allergic and Antipsychotic Drugs. Apart from these, the company also offersdrugs for
                                        Cardiovascular, Gastro & Antiemetic & Anti Ulcerant, Haematirics, Hormones,
                                        Neurology Supplements, Nutritional Supplements etc. The products are available as
                                        Tablets, Oral Liquid, Dry Powder, Capsules, Injectable, Ointment, and Drops. The
                                        products and processes at Biomax Biotechnics Pvt. Ltd., are WHO,GMP and ISO
                                        compliant. With prime focus on delivering world-className pharmaceutical products at
                                        affordable prices, the company has pan India presence.</p>
                                </div>

                                <div className="row lower-col">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="btn-box">
                                            <Link to="about.html" className="theme-btn btn-one">Read More</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-6 col-sm-12">
                                        <div className="icon-box">
                                            <i className="flaticon-phone"></i>
                                            <div className="content">
                                                <span>Need help? Call us:</span>
                                                <h5> +(91)-90238 22204 </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="choose-section-3-1 pt_100" style={{ backgroundImage: "url(assets/images/shape/shape-33.png)," }}>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <figure className="image">
                                <img src="assets/images/pcd-pharma-franchise.jpg" alt="" />
                            </figure>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="title-box  p_relative">
                                <h5 className="sec-sub-title">Get to know us</h5>
                                <div className="line-box">
                                    <span className="line-1"></span>
                                    <span className="line-2"></span>
                                    <span className="line-3"></span>
                                </div>
                                <h2 className="sec-title">Best Pharmaceuticals Company in India - Biomax Biotechnics </h2>
                                <div className="shape-1" style={{ backgroundImage: "url(assets/images/shape/shape-32.png)," }}></div>
                            </div>
                            <div className="director-speak">
                                <p>Biomax Biotechnics is a integrated best pharmaceuticals company. Biomax Biotechnics is one of the leading player in pharma industries business in india. We are engaged in trading and supplying a broad range of Medicines such as Tablets, Capsules and Syrups, Injections, Cream, Oil, Ayurvedic Products for Pharma Franchise and PCD</p>
                                <p>We Provides all promotional Inputs like Visual Aid Folder, Samples,Literatures etc.</p>
                                <ul>
                                    <li>Timely Delivery</li>
                                    <li>Competitive Prices</li>
                                    <li>Attractive Packaging</li>
                                    <li>Marketing Backup</li>
                                    <li>Wide Range with Latest Molecules</li>
                                    <li>Guaranteed Monopoly</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="auto-container">
                <div className="cta-section pr_100 pl_100" style={{ backgroundImage: "url(assets/images/background/cta-bg.png);" }}>
                    <div className="row">
                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <div className="image-box">
                                <div className="shape-1">
                                    <img src="assets/images/shape/shape-7.png" alt="" />
                                </div>
                                <div className="shape-2">
                                    <img src="assets/images/shape/shape-8.png" alt="" />
                                </div>
                                <div className="image">
                                    <img src="assets/images/resource/cta-1.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-12 box-1">
                            <h2>Let's we talk about the business for better grow up</h2>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 box-2">
                            <div className="btn-box">
                                <Link to="tel:+(91)-90238 22204" className="theme-btn btn-two">+(91)-90238 22204 </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="faq-section-3 sec-pad">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="title-box mb_30">
                                <h5 className="sec-sub-title">Why choose us</h5>
                                <div className="line-box">
                                    <span className="line-1"></span>
                                    <span className="line-2"></span>
                                    <span className="line-3"></span>
                                </div>
                                <h2 className="sec-title">Why mostly people choose us ! </h2>
                                <p className="text justify">
                                    We, Biomax Biotechnics Pvt. Ltd., are engaged in manufacturing and supplying a range of
                                    pharmaceutical formulations for Analgesics, Antibiotic & Anti-Infective, Anti-viral, &
                                    Anti Allergic, Antipsychotic Drugs and more. The New molecules ofour product range of
                                    BBPL approved from DCGI. Focused effort to provide quality drugs has created a niche for
                                    us across markets. We are a preferred name in the industry because of:
                                </p>
                            </div>
                            <div className="row g-4">

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq service-icon-box">
                                        <div className="icon-box">
                                            <div className="icon-box-inner">
                                                <i className="flaticon-task-list"></i>
                                            </div>
                                            <h5><Link to="#"> Ethical business practices</Link></h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq service-icon-box">
                                        <div className="icon-box">
                                            <div className="icon-box-inner">
                                                <i className="flaticon-task-list"></i>
                                            </div>
                                            <h5><Link to="#">ISO 9001:2008 certification</Link></h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq service-icon-box">
                                        <div className="icon-box">
                                            <div className="icon-box-inner">
                                                <i className="flaticon-task-list"></i>
                                            </div>
                                            <h5><Link to="#">50+ DCGI approved products</Link></h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq service-icon-box">
                                        <div className="icon-box">
                                            <div className="icon-box-inner">
                                                <i className="flaticon-task-list"></i>
                                            </div>
                                            <h5><Link to="#">3rd party manufacturing Facility</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq service-icon-box">
                                        <div className="icon-box">
                                            <div className="icon-box-inner">
                                                <i className="flaticon-task-list"></i>
                                            </div>
                                            <h5><Link to="#">Smart logistics & timely order delivery</Link></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq service-icon-box">
                                        <div className="icon-box">
                                            <div className="icon-box-inner">
                                                <i className="flaticon-task-list"></i>
                                            </div>
                                            <h5><Link to="#">Vast and in-depth industry experience</Link></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="faq-3-image-box">
                            <img src="assets/images/why-choose-us.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-section">
                <div className="shape-1" style={{ backgroundImage: "url(assets/images/shape/shape-10.png)", }}></div>
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <div className="left-col pt_90">
                                <div className="title-box tow mb_30">
                                    <h5 className="sec-sub-title">Contact With Us</h5>
                                    <div className="line-box">
                                        <span className="line-1"></span>
                                        <span className="line-2"></span>
                                        <span className="line-3"></span>
                                    </div>
                                    <h2 className="sec-title">Let’s Work Together?</h2>
                                </div>
                                <div className="form-inner">
                                    <form className="default-form">
                                        <div className="row clearfix">
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="text" name="username" placeholder="Your Name" />
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                                <input type="email" name="email" placeholder="Your Email *" required="" />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                <input type="text" name="phone" placeholder="Your Phone" />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                                                <input type="text" name="subject" placeholder="Your Address" />
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                                                <textarea name="message" placeholder="Message"></textarea>
                                            </div>
                                            <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn centred">
                                                <button className="theme-btn btn-one" type="submit" name="submit-form">Send
                                                    Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="shape-2" style={{ backgroundImage: "url(assets/images/shape/shape-11.png)", }}></div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="right-col" style={{ backgroundImage: "url(assets/images/shape/shape-12.png)", }}>
                                <figure className="image clearfix">
                                    <img src="/assets/images/resource/contact-1.png" alt="" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           


            <section className="faq-section pt_100 pb_100" style={{ backgroundImage: "url(assets/images/shape/shape-9.png)", }}>
                <div className="auto-container">
                    <div className="title-box mb_60 text-center">
                        <h5 className="sec-sub-title">Frequently Asked Questions</h5>
                        <div className="line-box">
                            <span className="line-1"></span>
                            <span className="line-2"></span>
                            <span className="line-3"></span>
                        </div>
                        <h2 className="sec-title white">Agency is Building a top <br />Business Sectors</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="faq-icon-box">
                                <i className="flaticon-right-arrow-1"></i>
                                <div className="content">
                                    <h5>Our Mission</h5>
                                    <p>Our mission is to provide accessible, affordable, and high-quality healthcare solutions.</p>
                                </div>
                            </div>
                            <div className="faq-icon-box">
                                <i className="flaticon-right-arrow-1"></i>
                                <div className="content">
                                    <h5>Our Vision</h5>
                                    <p>To be a pioneering force in the pharmaceutical industry, revolutionizing healthcare.</p>
                                </div>
                            </div>
                            <div className="faq-icon-box">
                                <i className="flaticon-right-arrow-1"></i>
                                <div className="content">
                                    <h5>Our Value</h5>
                                    <p>We uphold the highest ethical standards in all our actions, ensuring transparency, honesty, and accountability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <figure className="image">
                                <img src="assets/images/resource/faq-1.png" alt="" />
                            </figure>

                        </div>
                        <div className="col-lg-5">
                            <ul className="accordion-box">
                                <li className="accordion block active-block">
                                    <div className="acc-btn active">
                                        <div className="icon-box"><i className="flaticon-add"></i></div>
                                        <h4>WHAT IS PHARMA FRANCHISE?</h4>
                                    </div>
                                    <div className="acc-content current">
                                        <div className="text">
                                            <p>Pharma Franchise is a franchise business. Let’s understand what a franchise
                                                business is. When a company gives permission to a person or other company to
                                                sell its products by their name. It is called franchising a business.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="accordion block">
                                    <div className="acc-btn">
                                        <div className="icon-box"><i className="flaticon-add"></i></div>
                                        <h4>WHAT IS PCD PHARMA AND WHAT IS ITS FULL FORM?</h4>
                                    </div>
                                    <div className="acc-content">
                                        <div className="text">
                                            <p>Same as Pharma Franchise, Pharma PCD (Propaganda cum Distribution) Franchise
                                                refers authorization of distribution and marketing rights at monopoly basis
                                                provided by a pharmaceutical firm to Pharma distributor to use their company
                                                name or brand name on behalf of the company.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="accordion block">
                                    <div className="acc-btn">
                                        <div className="icon-box"><i className="flaticon-add"></i></div>
                                        <h4>IS PCD PHARMA DIFFERENT FROM PHARMA FRANCHISE?</h4>
                                    </div>
                                    <div className="acc-content">
                                        <div className="text">
                                            <p>Both PCD and Pharma Franchise are the same thing.</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="accordion block">
                                    <div className="acc-btn">
                                        <div className="icon-box"><i className="flaticon-add"></i></div>
                                        <h4>WHAT ARE THE DIFFERENT MODES OF TRANSPORTATION OF GOODS?</h4>
                                    </div>
                                    <div className="acc-content">
                                        <div className="text">
                                            <p>The different modes of transport which are used for transportation of goods
                                                are air, water, and land transport which includes rail, road. The customer
                                                has to decide which transport is most suitable for him</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials-section pt_100 pb_100">
                <div className="auto-container">
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="titles">
                                <h2>Our Testimonials</h2>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div className="testimonial-block-one" style={{ backgroundImage: "url(assets/images/shape/shape-20.png)" }}>
                                <div className="inner-box">
                                    <figure className="thumb-box flex-shrink-0"><img src="assets/images/testimonial-1.png" alt="" width="120px" /></figure>
                                    <div className="info-box flex-shrink-1">
                                        <h3>Karam Chand</h3>
                                        <h5 className="designation">Customer</h5>
                                        <p>I am working with the company from last 2.5 years. It is the best third party manufacturing company all over india. I have got very good response. Good quality products. Best company to deal with parties .</p>
                                    </div>
                                </div>
                                <div className="icon-box"><i className="fa fa-quote-right"></i></div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="testimonial-block-one" style={{ backgroundImage: "url(assets/images/shape/shape-20.png)" }}>
                                <div className="inner-box">
                                    <figure className="thumb-box flex-shrink-0"><img src="assets/images/testimonial-1.png" alt="" width="120px" /></figure>
                                    <div className="info-box flex-shrink-1">
                                        <h3>Sanjay Verma</h3>
                                        <h5 className="designation">Customer</h5>
                                        <p>I am working with the company from last 4 years. It is the best third party manufacturing company all over India. I have got very good response. Good quality products. best company to deal with</p>
                                    </div>
                                </div>
                                <div className="icon-box"><i className="fa fa-quote-right"></i></div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="testimonial-block-one" style={{ backgroundImage: "url(assets/images/shape/shape-20.png)" }}>
                                <div className="inner-box">
                                    <figure className="thumb-box flex-shrink-0"><img src="assets/images/testimonial-1.png" alt="" width="120px" /></figure>
                                    <div className="info-box flex-shrink-1">
                                        <h3>Ankush Agarwal</h3>
                                        <h5 className="designation"> Customer</h5>
                                        <p>Very nice pharma company to work with.. Excellent quality of the brand with innovative ideas always coming in its way</p>
                                    </div>
                                </div>
                                <div className="icon-box"><i class="fa fa-quote-right"></i></div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="testimonial-block-one" style={{ backgroundImage: "url(assets/images/shape/shape-20.png)" }}>
                                <div className="inner-box">
                                    <figure className="thumb-box flex-shrink-0"><img src="assets/images/testimonial-1.png" alt="" width="120px" /></figure>
                                    <div className="info-box flex-shrink-1">
                                        <h3>karan</h3>
                                        <h5 className="designation"> Customer</h5>
                                        <p>It is India's top third-party manufacturing company. I received some excellent feedback. high-quality goods. Best business to work with parties.No sacrificing quality.</p>
                                    </div>
                                </div>
                                <div className="icon-box"><i class="fa fa-quote-right"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="news-section sec-pad">
                <div className="auto-container">
                    <div className="title-box text-center mb_60">
                        <h5 className="sec-sub-title">Form Blogs</h5>
                        <div className="line-box">
                            <span className="line-1"></span>
                            <span className="line-2"></span>
                            <span className="line-3"></span>
                        </div>
                        <h2 className="sec-title">News &amp; Articles</h2>
                    </div>
                    <div className="row g-4 clearfix">

                        <div className="col-lg-4 col-md-6 col-sm-12 news-block">
                            <div className="news-block-one ">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image"><Link to="blog.html"><img src="assets/images/blog-one.jpg"
                                            alt="" /></Link></figure>
                                        <div className="post-info">
                                            <h5 className="date"><Link to="#">19 June, 2023</Link></h5>
                                            <h5 className="admin"><Link to="#">By admin</Link></h5>
                                        </div>
                                    </div>
                                    <div className="lower-content text-center">
                                        <h3><Link to="blog.html">PCD Pharma Franchise Company in India</Link></h3>
                                        <div className="link">
                                            <Link to="blog.html" className="news-btn-1">Read More</Link>
                                            <Link to="blog.html" className="news-btn-2"><i
                                                className="flaticon-right-arrow-2"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 news-block">
                            <div className="news-block-one ">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image"><Link to="blog.html"><img src="assets/images/blog_two.jpg"
                                            alt="" /></Link></figure>
                                        <div className="post-info">
                                            <h5 className="date"><Link to="#">19 June, 2023</Link></h5>
                                            <h5 className="admin"><Link to="#">By admin</Link></h5>
                                        </div>
                                    </div>
                                    <div className="lower-content text-center">
                                        <h3><Link to="blog.html">Benefits of Monopoly Pharma Franchise </Link></h3>
                                        <div className="link">
                                            <Link to="blog.html" className="news-btn-1">Read More</Link>
                                            <Link to="blog.html" className="news-btn-2"><i
                                                className="flaticon-right-arrow-2"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 news-block">
                            <div className="news-block-one ">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image"><Link to="blog.html"><img src="assets/images/blog-three.jpg"
                                            alt="" /></Link></figure>
                                        <div className="post-info">
                                            <h5 className="date"><Link to="#">19 June, 2023</Link></h5>
                                            <h5 className="admin"><Link to="#">By admin</Link></h5>
                                        </div>
                                    </div>
                                    <div className="lower-content text-center">
                                        <h3><Link to="blog.html">Scope of Pharma Franchise in India</Link></h3>
                                        <div className="link">
                                            <Link to="blog.html" className="news-btn-1">Read More</Link>
                                            <Link to="blog.html" className="news-btn-2"><i
                                                className="flaticon-right-arrow-2"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
