import React from 'react'
import { Link } from 'react-router-dom'

export default function Cerificate() {
  return (
    
      <>
      <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
        <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
        <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown"><Link to="/">Home</Link></li>
              <li>Certificate</li>
            </ul>
            <h1>Certificate</h1>
          </div>
        </div>
      </section>
     
    
      <section className="project-section pt_140" id="cert2">
        <div className="auto-container">
          <div className="title-box mb_60">
            <h5 className="sec-sub-title">Certificate</h5>
            <div className="line-box">
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
            </div>
            <h2 className="sec-title">Our Certifications<br /> Include</h2>
          </div>
        </div>
        <div className="container pb-100">
          <div className="project-carousel owl-dots-none">

            <div className="row">
              <div className="col-lg-4">
                <div className="projects">
                  <div className="image">
                    <img src="/assets/images/gmp-certi.png" alt="" />
                  </div>
                  <div className="content-box">

                    <h4>GMP-Certificate</h4>
                    <div className="icon-box">
                      <Link to="portfolio-grid.html"></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="projects">
                  <div className="image">
                    <img src="/assets/images/glp-certificate_400_300.png" alt="" />
                  </div>
                  <div className="content-box">

                    <h4>GLP-Certified</h4>
                    <div className="icon-box">
                      <Link to="portfolio-grid.html"></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="projects">
                  <div className="image">
                    <img src="/assets/images/ISO.jpeg" alt="" />
                  </div>
                  <div className="content-box">

                    <h4>ISO-Certified</h4>

                  </div>
                </div>
              </div>
            </div>








          </div>
        </div>
      </section>
      
    </>
   
  )
}
