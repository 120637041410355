import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';


export default function Product() {
    const { slug } = useParams();
    const [pros, setPro] = useState('')
    const [cate, setCate] = useState([])
    const [product, setProduct] = useState([])
    const products = () => {
        fetch(`https://dashboard.biomaxbiotechnics.in/api/product/${slug}`).then((res) => res.json())
            .then((result) => {
                setPro(result.pro)
                //console.log(result.pro)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const allproducts = () => {
        fetch('https://dashboard.biomaxbiotechnics.in/api/allproduct').then((ress) => ress.json())
            .then((results) => {
                setProduct(results.products)
                console.log(results.products)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const allcategory = () => {
        fetch('https://dashboard.biomaxbiotechnics.in/api/allCategories').then((res) => res.json())
            .then((result) => {
                setCate(result.cat)
                //console.log(result.cat)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    useEffect(() => {
        allproducts()
        products()
        allcategory()
    }, [slug])

    return (
        <>


            <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
                <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
                <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
                <div className="auto-container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li className="dropdown"><Link to="/">Home</Link></li>
                            <li>{pros.name}</li>
                        </ul>
                        <h1>{pros.name}</h1>
                    </div>
                </div>
            </section>


            <section>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-3 mt-30">
                            <div class="sidebar">

                                <div class="all_categorys">
                                    <h4>Dosage Form</h4>
                                    <ul>
                                        <li><Link to="/tablets">Tablets</Link></li>
                                        <li><Link to="/syrup">Syrup</Link></li>
                                        <li><Link to="/suspension">Suspension</Link></li>
                                        <li><Link to="/dry-syrup">Dry Syrup</Link></li>
                                        <li><Link to="/injections">Injections</Link></li>
                                        <li><Link to="/respules">Respules</Link></li>
                                        <li><Link to="/ivee">Ivee</Link></li>
                                        <li><Link to="/sachet">Sachet</Link></li>
                                        <li><Link to="/drops">Drop</Link></li>
                                        <li><Link to="/gels">Gels</Link></li>
                                        <li><Link to="/oil">Oil</Link></li>
                                        <li><Link to="/powder">Powder</Link></li>
                                        <li><Link to="/ayurvedic">Ayurvedic</Link></li>
                                        <li><Link to="/cough-syrup">Cough Syrup</Link></li>
                                    </ul>
                                </div>

                                <div class="all_categorys">
                                    <h4>Therapeutic wise</h4>
                                    <ul>
                                        <li><Link to="/anti-allergy">Anti Allergy</Link></li>
                                        <li><Link to="/laxative">Laxative</Link></li>
                                        <li><Link to="/ortho">Ortho</Link></li>
                                        <li><Link to="/gastro">Gastro</Link></li>
                                        <li><Link to="/pediatric">Pediatric</Link></li>
                                        <li><Link to="/anti-diarreahea">Anti diarreahea</Link></li>
                                        <li><Link to="/dental">Dental</Link></li>
                                        <li><Link to="/respiratory">Respiratory</Link></li>
                                        <li><Link to="/antacid">Antacid</Link></li>
                                        <li><Link to="/gyne">Gynee</Link></li>
                                        <li><Link to="/cardiac">Cardiac</Link></li>
                                        <li><Link to="/diabetic">Diabetic</Link></li>
                                        <li><Link to="/neuro">Neuro</Link></li>
                                        <li><Link to="/urology">Urology</Link></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-9 top">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="product-image">

                                        {
                                            pros.image ? (<img alt="" src={`https://dashboard.biomaxbiotechnics.in/${pros.image}`} />)
                                                :
                                                (<img alt="" src="/assets/images/istockphoto-1075374570-612x612.jpg" />)
                                        }
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="product-description">
                                        <h2><b>Brand Name : </b>{pros.name}</h2>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                      <b>Product Name </b>
                                                    </td>
                                                    <td>
                                                       {pros.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                      <b> Composition </b>
                                                    </td>
                                                    <td>
                                                       {pros.composition}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                      <b>Packing</b>
                                                    </td>
                                                    <td>
                                                    {pros.packing}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </section>



        </>
    )
}
