import React, { useEffect, useState } from 'react'

export default function Discontinue() {
    const [tablets, setTablets] = useState('')
    useEffect(() => {

        fetch(`https://dashboard.biomaxbiotechnics.in/api/discontinues-list`).then((res) => res.json())
            .then((result) => {
                setTablets(result.pro)

            })
            .catch((err) => {
                console.log(err)
            })

    }, [])
  return (
    <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div contentEditable='true' dangerouslySetInnerHTML={{ __html: tablets.description }}></div>
                </div>

            </div>
        </div>
  )
}
