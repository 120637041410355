import React from 'react'
import { Link } from 'react-router-dom'
export default function Biova
() {
  return (
    <>
      <section className="page-title-section p_relative text-center" style={{ backgroundImage: "url(/assets/images/SHKAS.webp)" }}>
        <div className="shape-1" style={{ backgroundImage: "url(/assets/images/shape/shape-34.png)" }}></div>
        <div className="shape-2" style={{ backgroundImage: "url(/assets/images/shape/shape-35.png)" }}></div>
        <div className="auto-container">
          <div className="content-box">
            <ul className="bread-crumb clearfix">
              <li className="dropdown"><Link to="/">Home</Link></li>
              <li>Visual Aids</li>
            </ul>
            <h1>Biomax Visual Aids</h1>
          </div>
        </div>
      </section>
      <section className="about-section-3 sec-pad" id="cert1">
      <div className="pattern-1" style={{ backgroundImage: "url(assets/images/shape/shape-9.png)", }}></div>
        <div className="container">
        <iframe title="bio" class="responsive-iframe" src="/assets/html/biomaxva.html"></iframe>
        </div>
      </section>



    </>
  )
}
