import React from 'react'

export default function Directormessage() {
    return (
        <>
            <section class="page-title">
                <div class="pattern-layer-one" style={{ backgroundImage: "url(images/background/pattern-16.png)" }}></div>
                <div class="auto-container">
                    <h2>Director Message</h2>
                    <ul class="page-breadcrumb">
                        <li><a href="/">home</a></li>
                        <li>Director Message</li>
                    </ul>
                </div>
            </section>
            <section class="about-section">
                <div class="auto-container">

                    <div class="row clearfix">


                        <div class="content-column col-lg-6 col-md-12 col-sm-12">
                            <div class="inner-column">
                                <h2><b>Managing Director</b></h2>
                                <div class="text">It gives us immense Pleasure to give an insight about company.

                                    “We are Building on our History to hard work, diligence and success by working Tirelessly. Since, we are committed to provide Extra Ordinary services in improving health and Quality of our Lives, across the country”, so we have take very fast growth in last 10 years.

                                    Whether you a consumer, healthcare professional or investor, please consider our website as your personal resource for learing more about BBPL and exploring our company.

                                    We appreciate your interset in our company, its products and people. It will also Continue to be a great place to work together. we continue to accomplish great things.</div>

                            </div>
                            <h6><b>SINCERELY,
                                K.S. CHAUHAN (MANAGING DIRECTOR)</b></h6>
                        </div>


                        <div class="images-column padding col-lg-6 col-md-12 col-sm-12">
                            <div class="inner-column" style={{ backgroundImage: "url(/assets/images/globe.png)" }}>
                                <div class="pattern-layer" style={{ backgroundIUmage: "url(/assets/images/pattern-1.png)" }}></div>
                                <div class="images-outers">
                                    <div class="image">
                                        <img src="/assets/images/biomax-k.s..jpg" alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
